import { getSubDomain } from '@guider-global/front-end-utils';

import { OrganizationIntegration } from '@guider-global/shared-types';
import { NoticeSnackbar, NoticeSnackbarProps } from '@guider-global/ui';
import { Box, Grid } from '@mui/material';
import { HubSpotIntegrationContainer } from 'containers';
import { useOrganizationIntegrations } from 'hooks';

import { useState } from 'react';
import { IntegrationDemoView, PageView } from 'views';

export function IntegrationsPage() {
  const subdomain = getSubDomain();

  const isDemo = subdomain === 'redbridge-partners' || subdomain === 'syntax';

  const {
    organizationIntegrations,
    reqOrganizationIntegrations,
    isLoadingOrganizationIntegrations,
  } = useOrganizationIntegrations({});

  const hubSpotIntegration = organizationIntegrations?.find(
    (integration) => integration.integrationName === 'hubspot',
  );
  const [showHubSpotModal, setShowHubSpotModal] = useState<boolean>(false);

  const [snackbarProps, setSnackbarProps] = useState<NoticeSnackbarProps>({
    show: false,
    message: '',
    variant: 'error',
  });

  async function enableIntegration(data: Partial<OrganizationIntegration>) {
    const { integrationName } = data;
    const result = await reqOrganizationIntegrations({
      method: 'PUT',
      url: `/admin/organizationIntegrations/${integrationName}`,
      data,
    });
    if (result.status !== 'success') {
      console.error({ result });
      const { message } = result;
      setSnackbarProps({
        show: true,
        message:
          message ?? `Unknown error adding ${integrationName} integration`,
        variant: 'error',
      });
    }
  }

  async function deleteIntegration(data: Partial<OrganizationIntegration>) {
    const { integrationName } = data;
    const result = await reqOrganizationIntegrations({
      method: 'DELETE',
      url: `/admin/organizationIntegrations/${integrationName}`,
    });
    if (result.status !== 'success') {
      console.error({ result });
      const { message } = result;
      setSnackbarProps({
        show: true,
        message:
          message ?? `Unknown error deleting ${integrationName} integration`,
        variant: 'error',
      });
    }
    setShowHubSpotModal(false);
  }
  return (
    <PageView
      title={'Integrations'}
      subtitles={['Manage all of your integrations in one place.']}
    >
      <NoticeSnackbar {...snackbarProps} />
      <Box minHeight="150vh" width="100%">
        <Grid
          container
          spacing={3}
          justifyContent={{ xs: 'center', md: 'left' }}
        >
          <Grid item>
            <HubSpotIntegrationContainer
              loading={isLoadingOrganizationIntegrations}
              integration={hubSpotIntegration}
              onNotification={(params) => setSnackbarProps(params)}
              onEnableIntegration={enableIntegration}
              onDeleteIntegration={deleteIntegration}
              modalOpen={showHubSpotModal}
              openModal={(state) => setShowHubSpotModal(state)}
            />
          </Grid>

          <IntegrationDemoView isDemo={isDemo} />
        </Grid>
      </Box>
    </PageView>
  );
}
