import { AlertBoxProps, ButtonStackItem, ErrorView } from '@guider-global/ui';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';

type ErrorPageProps = {
  title: string;
  messageOverride?: string;
  iconVariant?: AlertBoxProps['iconVariant'];
  dataOverride?: string;
  buttons: ButtonStackItem[];
};

export function ErrorPage({
  title,
  messageOverride,
  iconVariant,
  dataOverride,
  buttons,
}: ErrorPageProps) {
  const routeError: unknown = useRouteError();

  let statusText,
    message = 'Unknown error',
    data = '';

  if (routeError instanceof Error) {
    message = routeError.message;
  }

  if (isRouteErrorResponse(routeError)) {
    console.error(routeError);
    statusText = routeError.statusText;
    message = routeError.statusText;
    data = routeError.data;
  }

  return (
    <ErrorView
      title={title}
      message={messageOverride ?? statusText ?? message}
      iconVariant={iconVariant ?? 'warning'}
      data={dataOverride ?? data}
      buttons={buttons}
    />
  );
}
