import { Outlet } from 'react-router-dom';

export type PlaceholderPageProps = {
  title: string;
};
export function PlaceholderPage({ title }: PlaceholderPageProps) {
  return (
    <>
      <h2>{title}</h2>
      <Outlet />
    </>
  );
}
