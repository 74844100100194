import { IFilterModel } from '@guider-global/shared-types';
import {
  ExportDropDownButtonProps,
  LegendItem,
  StackedBarChart,
  TextStackItem,
} from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { AxisConfig, BarChartProps } from '@mui/x-charts';
import { getTruncateYAxisLabels } from 'functions';
import { RefObject, forwardRef } from 'react';

export interface BarChartContent extends BarChartProps {
  series: BarChartProps['series'];
  dataset: BarChartProps['dataset'];
  loading: boolean;
  title?: string;
  subtitles?: TextStackItem[];
  filterModel?: IFilterModel;
  legendItems: LegendItem[];
}

export interface StackedBarCharViewProps {
  dataKey: string;
  exportDropdownButtons: ExportDropDownButtonProps[];
  barChartProps: BarChartContent;
}

export const StackedBarChartView = forwardRef<
  HTMLDivElement,
  StackedBarCharViewProps
>(({ dataKey, barChartProps, exportDropdownButtons }, ref) => {
  const { breakpoints } = useTheme();
  const isLaptop = useMediaQuery(breakpoints.down('lg'));

  return (
    <StackedBarChart
      ref={ref}
      exportDropdownButtons={exportDropdownButtons}
      yAxis={[
        {
          valueFormatter: (value: string) =>
            getTruncateYAxisLabels({
              label: value,
              chartRef: ref as RefObject<HTMLDivElement>,
              maxWidth: 41,
            }),
          scaleType: 'band',
          dataKey: dataKey,
          tickLabelStyle: isLaptop
            ? {
                angle: 45,
                fontSize: isLaptop ? '8px' : '12px',
              }
            : undefined,
        } as AxisConfig<'band'>,
      ]}
      {...barChartProps}
    />
  );
});
