import { buildSanityImageUrl, useSettings } from '@guider-global/sanity-hooks';
import { IntegrationCard } from '@guider-global/ui';
import { Grid } from '@mui/material';

export interface IntegrationDemoViewProps {
  isDemo?: boolean;
}

export function IntegrationDemoView({ isDemo }: IntegrationDemoViewProps) {
  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  if (!isDemo) return <></>;

  return (
    <>
      <Grid item>
        <IntegrationCard
          heading={'Degreed'}
          text={
            'Degreed’s learning technology suite helps companies of all sizes — from fast-growing start-ups to global enterprises.'
          }
          avatarSrc={buildSanityImageUrl({
            source: settings?.static_media?.integrations?.degreed_logo ?? '',
          })}
          isConnected
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          heading={'Enterprise SSO'}
          text={
            'Enable all end-users to securely access all of your enterprise’s applications with a single click.'
          }
          avatarSrc={buildSanityImageUrl({
            source:
              settings?.static_media?.integrations?.enterprise_sso_logo ?? '',
          })}
          isConnected
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          beta
          heading={'MS Teams'}
          text={
            'Merge the power of Guider with the familiarity of Microsoft Teams. No switching between apps, no hassle - just pure, streamlined mentorship management.'
          }
          avatarSrc={buildSanityImageUrl({
            source:
              settings?.static_media?.integrations?.active_directory_teams ??
              '',
          })}
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          heading={'Slack'}
          beta
          text={
            'Simplify the process of connecting with people and resources, with Slack, across your company to share best practices, find help or advice, and learn new skills.'
          }
          avatarSrc={buildSanityImageUrl({
            source: settings?.static_media?.integrations?.slack_logo ?? '',
          })}
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          heading={'CSV Data Import'}
          text={'Data file import made easy.'}
          avatarSrc={buildSanityImageUrl({
            source: settings?.static_media?.integrations?.csv_import_logo ?? '',
          })}
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          heading={'Docebo'}
          text={
            'Extend the value of your Docebo platform with an integration solution that is seamless, flexible, and fit your unique business needs.'
          }
          avatarSrc={buildSanityImageUrl({
            source: settings?.static_media?.integrations?.docebo_logo ?? '',
          })}
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          heading={'360 Learning'}
          text={
            'Bring the power of the 360Learning platform wherever work takes you with our seamless integration.'
          }
          avatarSrc={buildSanityImageUrl({
            source:
              settings?.static_media?.integrations?.learning360_logo ?? '',
          })}
          onClick={() => {}}
        />
      </Grid>
      <Grid item>
        <IntegrationCard
          heading={'Outlook'}
          text={
            'Save time moving between apps and conversations, schedule sessions in one place. Guider makes it easy for mentors and mentees to view shared availability and to book sessions.'
          }
          avatarSrc={buildSanityImageUrl({
            source: settings?.static_media?.integrations?.outlook_logo ?? '',
          })}
          onClick={() => {}}
        />
      </Grid>
    </>
  );
}
