import { Grid as MuiGrid, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

export type DashboardViewProps = {
  header: JSX.Element;
  navigation: JSX.Element;
  footer: JSX.Element;
  isMobile: boolean;
  children?: JSX.Element;
};

export function DashboardView({
  header,
  navigation,
  footer,
  isMobile,
  children,
}: DashboardViewProps) {
  const { palette } = useTheme();
  return (
    <MuiGrid container>
      <MuiGrid
        item
        xs={3}
        sx={
          isMobile
            ? { display: 'none' }
            : {
                backgroundColor: palette.navigation.background.primary,
              }
        }
      >
        <nav
          style={{
            position: 'sticky',
            top: 0,
            overflow: 'auto',
            scrollbarGutter: 'stable',
          }}
        >
          {navigation}
        </nav>
      </MuiGrid>
      <MuiGrid item xs={12} md={9}>
        <MuiGrid container>
          <MuiGrid item xs={12}>
            <header>{header}</header>
          </MuiGrid>
          <MuiGrid
            item
            xs={12}
            sx={!isMobile ? { display: 'none' } : undefined}
          >
            <nav>{navigation}</nav>
          </MuiGrid>
          <MuiGrid item xs={12} sx={{ minHeight: '80vh' }}>
            <main>{children ?? <Outlet />}</main>
          </MuiGrid>
          <MuiGrid item xs={12}>
            <footer>{footer}</footer>
          </MuiGrid>
        </MuiGrid>
      </MuiGrid>
    </MuiGrid>
  );
}
