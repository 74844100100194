import {
  FilterStack,
  MetricsTable,
  MetricsTableProps,
  TFilter,
} from '@guider-global/ui';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export interface SkillsMetricsTableViewProps
  extends Omit<MetricsTableProps, 'paginationMode'> {
  filters: TFilter[];
}

export function SkillsMetricsTableView({
  filters,
  ...props
}: SkillsMetricsTableViewProps) {
  return (
    <MetricsTable
      {...props}
      paginationMode="client"
      toolbar={() =>
        DefaultCustomToolbar({ fileName: props.fileName, filters })
      }
    />
  );
}

interface DefaultCustomToolbarProps {
  fileName?: string;
  filters: TFilter[];
}
function DefaultCustomToolbar({
  fileName,
  filters,
}: DefaultCustomToolbarProps) {
  return (
    <GridToolbarContainer
      sx={{
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        px: 1,
      }}
    >
      <FilterStack filters={filters} />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fileName: `${fileName}`,
        }}
      />
    </GridToolbarContainer>
  );
}
