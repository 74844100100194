import { IOrganization } from '@guider-global/shared-types';
import {
  GuiderAdminLogo,
  GuiderIcon,
  Text,
  Stack,
  AvatarButton,
  Button,
  ButtonStackItem,
  Pagination,
} from '@guider-global/ui';

export interface OrganizationButton extends IOrganization {
  onClick: React.MouseEventHandler<HTMLDivElement>;
}

export type OrganizationsViewProps = {
  title: string;
  buttons: ButtonStackItem[];
  organizations: OrganizationButton[];
  isPaginated?: boolean;
  totalPages?: number;
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  currentPage?: number;
};

export function OrganizationsView({
  title,
  buttons,
  organizations,
  isPaginated,
  totalPages,
  onPageChange,
  currentPage,
}: OrganizationsViewProps) {
  return (
    <Stack direction="column" align="center">
      <GuiderIcon size="large" />
      <GuiderAdminLogo size="medium" />
      <Text text={title} variant="h1" />
      {organizations.map((organization) => (
        <AvatarButton
          key={organization.slug}
          size="medium"
          avatarSrc={
            organization.branding?.logoUrl ? organization.branding?.logoUrl : ''
          }
          heading={organization.displayName ? organization.displayName : ''}
          sx={{ width: { xs: '90vw', md: '450px' } }}
          onClick={organization.onClick}
        />
      ))}
      {isPaginated && (
        <Pagination
          count={totalPages}
          color="secondary"
          onChange={onPageChange}
          page={currentPage}
        />
      )}
      {buttons.map(({ key, ...buttonProps }) => {
        return <Button key={key} {...buttonProps} />;
      })}
    </Stack>
  );
}
