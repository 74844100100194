import { useTheme } from '@mui/material';
import { UserSearchContainer } from 'containers';
import { PageView } from 'views';

export function UsersSearchPage() {
  const { palette } = useTheme();

  return (
    <PageView
      title="Users"
      subtitles={['Search for a user']}
      headerSx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
      sx={{
        backgroundColor: palette.paper.background.primary,
      }}
    >
      <UserSearchContainer />
    </PageView>
  );
}
