import { SnackbarOrigin } from '@mui/material';
import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

export interface ISnackbar {
  show: boolean;
  title?: string;
  variant?: 'success' | 'info' | 'warning' | 'error';
  message: string;
  duration?: number;
  position?: SnackbarOrigin;
  onClose?: () => void;
  onNavigate?: () => void;
}

const initialState: ISnackbar = {
  show: false,
  title: undefined,
  message: '',
  duration: 8000,
  position: { vertical: 'bottom', horizontal: 'right' },
  variant: undefined,
};

export const snackbarSlice: Slice<ISnackbar> = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<Partial<ISnackbar>>) => {
      return { ...state, ...action.payload };
    },
    clearSnackbar: () => {
      return { ...initialState };
    },
  },
});

export const { setSnackbar, clearSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
