import {
  FilterStack,
  IDateFilterValue,
  IMultiSelectChipFilterOption,
  TFilter,
} from '@guider-global/ui';
import { useMediaQuery, useTheme } from '@mui/material';
import { parseCountryToString, parseDateFilterToString } from 'functions';

export interface IDefaultFilterValues {
  email?: string;
  firstName?: string;
  lastName?: string;
  dateRange?: IDateFilterValue;
  country?: IMultiSelectChipFilterOption[];
}

export interface MetricsFilterViewProps {
  dateRange: IDateFilterValue | undefined;
  onDateChange: (value: IDateFilterValue) => void;
  email: string | undefined;
  onEmailChange: (value?: string) => void;
  firstName: string | undefined;
  onFirstNameChange: (value?: string) => void;
  lastName: string | undefined;
  onLastNameChange: (value?: string) => void;
  country: IMultiSelectChipFilterOption[] | undefined;
  onCountryChange: (options?: IMultiSelectChipFilterOption[]) => void;
  countryOptions: IMultiSelectChipFilterOption[];
  countryEnabled?: boolean;
  updateFilterValues: (value: Partial<IDefaultFilterValues>) => void;
  customFilters?: TFilter[];
  onModelApply?: () => void;
}

export function MetricsFilterView({
  dateRange,
  onDateChange,
  email,
  onEmailChange,
  firstName,
  onFirstNameChange,
  lastName,
  onLastNameChange,
  country,
  onCountryChange,
  countryOptions,
  countryEnabled,
  updateFilterValues,
  customFilters = [],
  onModelApply,
}: Readonly<MetricsFilterViewProps>) {
  const { breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down('sm'));
  const isMedium = useMediaQuery(breakpoints.down('md'));
  const isLarge = useMediaQuery(breakpoints.down('lg'));

  const countryFilter: TFilter[] = countryEnabled
    ? [
        {
          filterType: 'multiSelectChip',
          id: 'country-select',
          value: country,
          options: countryOptions,
          appBarProps: {
            anchorButton: {
              label: 'Country',
              value: parseCountryToString(country),
              id: 'chip-picker-button',
              tooltip: 'Country',
            },
            clearButton: {
              label: 'Clear',
              onClick: () => {
                updateFilterValues({ country: undefined });
                onCountryChange();
              },
            },
            submitButton: {
              label: 'Submit',
              onClick: () => onCountryChange(country),
            },
          },
          onChange: (event, value) => updateFilterValues({ country: value }),
          showInModal: isMedium,
          modalItemProps: {
            header: 'Country',
          },
        },
      ]
    : [];

  const filters: TFilter[] = [
    {
      filterType: 'date',
      id: 'date',
      labelFrom: 'From',
      labelTo: 'To',
      onChange: onDateChange,
      value: dateRange,
      appBarProps: {
        anchorButton: {
          tooltip: 'Date Range',
          label: 'Date Range',
          value: parseDateFilterToString(dateRange),
          id: 'date-picker-button',
          sx: { minWidth: '170px' },
        },
      },
    },
    {
      filterType: 'textSearch',
      id: 'email-search',
      onChange: (event) => updateFilterValues({ email: event.target.value }),
      value: email,
      showInModal: isSmall,
      modalItemProps: {
        header: 'Email',
      },
      appBarProps: {
        anchorButton: {
          tooltip: 'Email',
          label: 'Email',
          value: email,
          id: 'email-search-button',
        },
        clearButton: {
          label: 'Clear',
          onClick: () => {
            updateFilterValues({ email: undefined });
            onEmailChange();
          },
        },
        submitButton: {
          label: 'Submit',
          onClick: () => onEmailChange(email),
        },
      },
    },
    {
      filterType: 'textSearch',
      id: 'first-name-search',
      onChange: (event) =>
        updateFilterValues({ firstName: event.target.value }),
      value: firstName,

      appBarProps: {
        anchorButton: {
          tooltip: 'First Name',
          label: 'First Name',
          value: firstName,
          id: 'first-name-search-button',
        },
        clearButton: {
          label: 'Clear',
          onClick: () => {
            updateFilterValues({ firstName: undefined });
            onFirstNameChange();
          },
        },
        submitButton: {
          label: 'Submit',
          onClick: () => onFirstNameChange(firstName),
        },
      },
      showInModal: isLarge,
      modalItemProps: {
        header: 'First Name',
      },
    },
    {
      filterType: 'textSearch',
      id: 'last-name-search',
      onChange: (event) => updateFilterValues({ lastName: event.target.value }),
      value: lastName,
      appBarProps: {
        anchorButton: {
          tooltip: 'Last Name',
          label: 'Last Name',
          value: lastName,
          id: 'last-name-search-button',
        },
        clearButton: {
          label: 'Clear',
          onClick: () => {
            updateFilterValues({ lastName: undefined });
            onLastNameChange();
          },
        },
        submitButton: {
          label: 'Submit',
          onClick: () => onLastNameChange(lastName),
        },
      },
      showInModal: isLarge,
      modalItemProps: {
        header: 'Last Name',
      },
    },
    ...countryFilter,
    ...customFilters,
  ];
  const handleModelApply = () => {
    if (onModelApply) {
      onModelApply();
    }
    if (email) {
      onEmailChange(firstName);
    }
    if (firstName) {
      onFirstNameChange(firstName);
    }
    if (lastName) {
      onLastNameChange(lastName);
    }
    if (country) {
      onCountryChange(country);
    }
  };

  return (
    <FilterStack
      header="Filters"
      filters={filters}
      modal={{
        title: 'More Filters',
        closeButtonLabel: 'Close',
        applyButtonLabel: 'Apply',
        onApply: handleModelApply,
      }}
    />
  );
}
