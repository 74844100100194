import {
  DropDownButton,
  ListItemButton,
  MetricsTable,
  MetricsTableProps,
} from '@guider-global/ui';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useCallback } from 'react';

export interface MongoMetricsViewProps {
  metricsTableProps: Pick<
    MetricsTableProps,
    | 'heading'
    | 'rows'
    | 'columns'
    | 'loading'
    | 'onRowClick'
    | 'filterMode'
    | 'filterModel'
    | 'onFilterModelChange'
    | 'sortingMode'
    | 'onSortModelChange'
    | 'sortModel'
    | 'pageSizeOptions'
    | 'paginationModel'
    | 'paginationMode'
    | 'onPaginationModelChange'
  >;
  exportDropdownButtons: {
    key: string;
    onClick: () => void;
    label: string;
  }[];
}

export function MongoMetricsView({
  metricsTableProps,
  exportDropdownButtons,
}: MongoMetricsViewProps) {
  const toolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ justifyContent: 'space-between', px: 1 }}>
        <GridToolbarFilterButton />
        <DropDownButton
          variant="text"
          label="Export"
          startIcon={<FileDownloadOutlinedIcon />}
          color="info"
          loading={false}
        >
          {exportDropdownButtons.map(({ onClick, label, key }) => (
            <ListItemButton key={key} onClick={onClick}>
              {label}
            </ListItemButton>
          ))}
        </DropDownButton>
      </GridToolbarContainer>
    ),
    [exportDropdownButtons],
  );

  return (
    <>
      <MetricsTable {...metricsTableProps} toolbar={toolbar} />
    </>
  );
}
