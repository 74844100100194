import { Auth0Provider } from '@auth0/auth0-react';
import { getOrigin, getSubDomain } from '@guider-global/front-end-utils';
import { ErrorView } from '@guider-global/ui';

type Auth0WrapperProps = {
  children?: React.ReactNode;
};

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_SCOPES,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_BASE_API_URL,
} = process.env;

export function Auth0Wrapper({ children }: Auth0WrapperProps) {
  function getErrorMessage() {
    if (!REACT_APP_AUTH0_DOMAIN) {
      return `REACT_APP_INTERCOM_APP_ID is not defined`;
    } else if (!REACT_APP_AUTH0_CLIENT_ID) {
      return `REACT_APP_AUTH0_CLIENT_ID is not defined`;
    }
    return '';
  }

  if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID) {
    return (
      <ErrorView
        title={'Auth0 is not configued'}
        message={getErrorMessage()}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }

  const organization = getSubDomain();
  const redirectUri = getOrigin();

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        organization,
        redirect_uri: redirectUri,
        scope: REACT_APP_SCOPES,
        audience: REACT_APP_AUTH0_AUDIENCE ?? REACT_APP_BASE_API_URL,
      }}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
}
