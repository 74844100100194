import { getTimezoneAbbreviation } from '@guider-global/isomorphic-utils';
import { useProgram } from '@guider-global/sanity-hooks';
import { EProgramVariation } from '@guider-global/shared-types';
import {
  ProgramGroupTableProvider,
  ProgramIndividualTableProvider,
} from 'context';
import { useParams } from 'react-router-dom';
import { GroupProgramMetricsView } from 'views/GroupProgramMetricsView';
import { IndividualProgramMetricsView } from 'views/IndividualProgramMetricsView';

export function ProgramMetricsPage() {
  // Program
  const { programSlug = '' } = useParams();

  const { program, isLoadingProgram } = useProgram({
    programSlug,
    localeCode: 'en_GB',
  });

  if (!programSlug) {
    return <></>;
  }

  const programVariation = program?.program_details?.program_variation;

  if (!program || isLoadingProgram) {
    return <></>;
  }
  if (programVariation === EProgramVariation['Group']) {
    return (
      <ProgramGroupTableProvider>
        <GroupProgramMetricsView program={program} />
      </ProgramGroupTableProvider>
    );
  }
  if (programVariation === EProgramVariation['Individual']) {
    return (
      <ProgramIndividualTableProvider>
        <IndividualProgramMetricsView program={program} />
      </ProgramIndividualTableProvider>
    );
  }
  return <></>;
}

export function getUpdatedAt(): string | undefined {
  try {
    const now = new Date();
    const localeData = Intl.DateTimeFormat().resolvedOptions();

    const updatedAtString = `Updated today at ${now.toLocaleTimeString(
      localeData.locale,
      {
        hour12: true,
      },
    )} (${getTimezoneAbbreviation(localeData.timeZone)})`;

    return updatedAtString;
  } catch (err: unknown) {
    return undefined;
  }
}
