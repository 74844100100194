import { IconButton, Text } from '@guider-global/ui';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Alert, Box, CircularProgress, Stack } from '@mui/material';

export interface ChartDownloadStatusProps {
  status: 'in-progress' | 'success' | 'error';
  filename: string;
  onDismiss: () => void;
}

export function ChartDownloadStatus({
  status,
  filename,
  onDismiss,
}: ChartDownloadStatusProps) {
  const getSeverity = () => {
    switch (status) {
      case 'in-progress':
        return 'info';
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'info';
    }
  };

  const severity = getSeverity();
  const showProgress = status === 'in-progress';
  const showSuccess = status === 'success';
  const showError = status === 'error';

  return (
    <Alert
      severity={severity}
      icon={false}
      sx={{
        mb: 2,
        '&>*:nth-child(1)': {
          width: '100%',

          p: 0,
        },
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={1.5} alignItems="center">
          {showProgress ? <CircularProgress size="1.5rem" /> : undefined}
          {showSuccess ? <CheckCircleOutlineIcon color="success" /> : undefined}
          {showError ? <PriorityHighIcon color="error" /> : undefined}
          <Text text={filename} component="div" />
        </Stack>
        <Box alignSelf="end">
          <IconButton
            variant="regular"
            icon={<ClearIcon />}
            size="small"
            onClick={onDismiss}
          />
        </Box>
      </Stack>
    </Alert>
  );
}
