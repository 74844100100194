import { useAuth } from '@guider-global/auth-hooks';
import { getOrgUrl, getOrigin } from '@guider-global/front-end-utils';
import { IOrganization } from '@guider-global/shared-types';
import { Loading } from '@guider-global/ui';
import Logout from '@mui/icons-material/Logout';
import { useAppContext } from 'context';
import { useOrganizations } from 'hooks/useOrganizations';
import { useNavigate } from 'react-router-dom';

import { OrganizationsView } from 'views';
const PAGE_LIMIT = 50;

export function OrganizationsPage() {
  const { logout, isLoading: isLoadingAuth0, loginWithRedirect } = useAuth({});
  const { isRootDomain, setOrganizationSlug } = useAppContext();
  const navigate = useNavigate();

  const {
    organizations,
    isLoadingOrganizations,
    isErrorOrganizations,
    getPage,
    getMeta,
  } = useOrganizations({
    getSilently: true,
    getSilentlyUrl: isRootDomain
      ? '/superadmin/organizations'
      : '/organizations',
    ...(isRootDomain && { pageLimit: PAGE_LIMIT }),
    isPaginated: isRootDomain,
  });
  const organizationsList = organizations();
  const meta = getMeta();
  const paginationMeta = meta?.pagination?.isPaginated
    ? meta?.pagination
    : undefined;
  const currentPage = paginationMeta?.page ?? 1;

  const totalPages =
    organizationsList.length < PAGE_LIMIT ? currentPage : currentPage + 1;

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    getPage({ page });
  };

  const handleOnClickOrganization = async (organization: IOrganization) => {
    if (isRootDomain) {
      setOrganizationSlug(organization.slug);
      navigate('/metrics');
    } else {
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: getOrgUrl(organization.slug).toString(),
          organization: organization.id,
        },
      });
    }
  };

  if (isLoadingAuth0 || isLoadingOrganizations()) {
    return <Loading withBackdrop={true} isLoading={true} />;
  }

  if (isErrorOrganizations()) {
    throw new Error('Could not find organizations');
  }
  return (
    <OrganizationsView
      organizations={organizationsList.map((organization) => {
        return {
          ...organization,
          onClick: async () => await handleOnClickOrganization(organization),
        };
      })}
      title="Select an organization"
      buttons={[
        {
          key: 'log-out',
          variant: 'textWithIcon',
          label: 'Logout',
          onClick: () =>
            logout({ logoutParams: { returnTo: getOrigin() + '/login' } }),
          startIcon: <Logout />,
        },
      ]}
      isPaginated
      onPageChange={handlePageChange}
      totalPages={totalPages}
      currentPage={currentPage}
    />
  );
}
