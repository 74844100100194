import {
  DropDown,
  IconButton,
  PersonOffIcon,
  AvatarBox,
  PersonIcon,
} from '@guider-global/ui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MenuItem as MuiMenuItem } from '@mui/material';

export type RelationshipManageDropDownViewProps = {
  status?: Boolean;
  onConcludeRelationship?: () => void;
  onActivateRelationship?: () => void;
};

export function RelationshipManageDropDownView({
  onConcludeRelationship,
  onActivateRelationship,
  status,
}: RelationshipManageDropDownViewProps) {
  return (
    <DropDown
      anchor={<IconButton variant="regular" icon={<MoreHorizIcon />} />}
    >
      <MuiMenuItem
        onClick={
          status === true ? onActivateRelationship : onConcludeRelationship
        }
      >
        {status === false ? (
          <AvatarBox
            icon={<PersonOffIcon size="small" />}
            size="small"
            heading="Conclude Relationship"
            subtitles={[
              'This will conclude the relationship meaning that both users wil no longer be able to message or schedule a session.',
            ]}
          />
        ) : (
          <AvatarBox
            icon={<PersonIcon size="small" />}
            size="small"
            heading="Activate Relationship"
            subtitles={[
              'This will activate the relationship, the users will be able to schedule sessions and chat with each other.',
            ]}
          />
        )}
      </MuiMenuItem>
    </DropDown>
  );
}
