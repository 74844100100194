import { ConfirmationModal } from '@guider-global/ui';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useTheme } from '@mui/material';

export type RelationshipActivateModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function RelationshipActivateModal({
  isOpen,
  onConfirm,
  onCancel,
}: RelationshipActivateModalProps) {
  const { palette } = useTheme();
  return (
    <ConfirmationModal
      icon={<PersonOffIcon />}
      open={isOpen}
      title="Activate Relationship"
      description="This will activate the relationship, the users will be able to schedule sessions and chat with each other."
      buttons={[
        {
          label: 'Confirm',
          onClick: onConfirm,
          key: 'confirm-relationship-activate-button',
          variant: 'contained',
          color: 'primary',
        },
        {
          label: 'Cancel',
          onClick: onCancel,
          key: 'cancel-relationship-activate-button',
          variant: 'outlined',
          color: 'secondary',
        },
      ]}
      onClose={onCancel}
      sx={{ color: palette.primary.main }}
    />
  );
}
