import { ErrorView } from '@guider-global/ui';
import { Outlet } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

export type IntercomWrapperProps = { children: JSX.Element };

export function IntercomWrapper({ children }: IntercomWrapperProps) {
  const { REACT_APP_INTERCOM_APP_ID } = process.env;
  if (!REACT_APP_INTERCOM_APP_ID) {
    return (
      <ErrorView
        title={'Intercom is not configued'}
        message={'REACT_APP_INTERCOM_APP_ID is not defined'}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }
  return (
    <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID} autoBoot>
      {<>{children}</> ?? <Outlet />}
    </IntercomProvider>
  );
}
