import { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import {
  CreateReduxSlice,
  createReduxSlice,
  IReduxState,
} from '@guider-global/redux-axios-hooks';

export interface ITestState extends IReduxState {
  text: string;
  boolean: boolean;
}

export const initialState: ITestState = {
  loading: false,
  success: false,
  error: false,
  errors: [],
  text: '',
  boolean: false,
};

const slice: CreateReduxSlice<
  ITestState,
  SliceCaseReducers<ITestState>,
  'test'
> = createReduxSlice({
  name: 'test',
  initialState,
  reducers: {
    setText(state, { payload }: PayloadAction<string>) {
      return {
        ...state,
        text: payload,
      };
    },
    setBoolean(state, { payload }: PayloadAction<boolean>) {
      return { ...state, boolean: payload };
    },
  },
});

const { actions, reducer } = slice;

const selector: (state: ITestState) => ITestState = (state: ITestState) =>
  state;

export const testSlice = { initialState, actions, reducer, selector };
