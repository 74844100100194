import { useAuth0 } from '@auth0/auth0-react';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
} from '@guider-global/sanity-hooks';
import { Loading } from '@guider-global/ui';
import { useAppContext } from 'context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { OnboardingView } from 'views';

const { REACT_APP_REGISTRATION_URL } = process.env;

export function LoginPage() {
  const {
    loginWithRedirect,
    isLoading: isLoadingAuth0,
    isAuthenticated,
  } = useAuth0();

  const { organizationSlug } = useAppContext();

  const navigate = useNavigate();

  const subdomain = getSubDomain();

  const isGuiderLogin = organizationSlug === 'guider';

  const { organization, isLoadingOrganization, isErrorOrganization } =
    useOrganization({
      organizationSlug: organizationSlug ?? subdomain,
      localeCode: 'en_GB',
    });

  const organizationPicture = !isGuiderLogin
    ? organization?.white_label.auth0_logo
    : undefined;

  const organizationName = organization?.basic_info.name;

  async function handleLogin() {
    const organizationAuth0Id = organization?.basic_info.auth0_organization_id;
    await loginWithRedirect({
      appState: {
        returnTo: '/login',
      },
      authorizationParams: {
        organization: organizationAuth0Id,
      },
    });
  }

  async function handleRegister() {
    if (REACT_APP_REGISTRATION_URL) {
      const url = new URL(REACT_APP_REGISTRATION_URL);
      const { protocol, hostname, pathname } = url;
      const redirectUrl = window.location.href;
      const queryParams = new URLSearchParams({
        redirect: redirectUrl,
      });
      const nextUrl = new URL(
        `${pathname}?${queryParams}`,
        `${protocol}${organizationSlug}.${hostname}`,
      );
      window.location.assign(nextUrl.toString());
    }
  }

  useEffect(() => {
    if (!isLoadingAuth0 && isAuthenticated) {
      navigate('/metrics');
    }
  }, [organizationSlug, isLoadingAuth0, isAuthenticated, navigate]);

  if (isLoadingAuth0 || isLoadingOrganization) {
    return <Loading withBackdrop={true} isLoading={true} />;
  }

  if (isErrorOrganization) {
    throw new Error(`The organization ${organizationSlug} could not be found`);
  }

  if (!isAuthenticated) {
    return (
      <OnboardingView
        avatarSrc={
          organizationPicture
            ? buildSanityImageUrl({ source: organizationPicture })
            : undefined
        }
        title={isGuiderLogin ? 'Login' : `Login to ${organizationName}`}
        buttonStackProps={{
          direction: 'column',
          width: '450px',
          buttons: [
            {
              key: 'register',
              variant: 'contained',
              label: 'Register',
              onClick: () => handleRegister(),
              disabled: isGuiderLogin,
            },
            {
              key: 'login',
              variant: 'contained',
              label: 'Login',
              onClick: () => handleLogin(),
            },
          ],
        }}
      />
    );
  }

  return <></>;
}
