import { AlertBox, Stack } from '@guider-global/ui';

export type ProgramMembershipEmptyTableViewProps = {
  heading: string;
};

export function ProgramMembershipEmptyTableView({
  heading,
}: ProgramMembershipEmptyTableViewProps) {
  return (
    <Stack
      direction="column"
      align="center"
      spacing={2}
      sx={{
        border: (theme) => `1px solid ${theme.palette.paper.border.primary}`,
        borderRadius: 1,
        pb: 3,
      }}
    >
      <AlertBox variant="iconWithBackground" title={heading} />
    </Stack>
  );
}
