import { useAuth0 } from '@auth0/auth0-react';
import { getOrigin } from '@guider-global/front-end-utils';
import { useNavigate } from 'react-router-dom';

export function LogoutPage() {
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const navigate = useNavigate();

  if (isLoading) {
    return <>Loading..</>;
  }

  if (isAuthenticated) {
    logout({ logoutParams: { returnTo: getOrigin() + '/login' } });
    return <>Logging out..</>;
  }

  if (!isAuthenticated) {
    navigate('/login');
    return <>Redirecting..</>;
  }
  return <>Refresh your page</>;
}
