import { Chip, ErrorView } from '@guider-global/ui';
import { ProfileProgramOverviewListContainer } from 'containers';
import { useAppContext } from 'context';
import { useAdminUserProfiles, useSnackbar } from 'hooks';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { DropDownItem, SubPageView } from 'views';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { DeleteUserModal } from 'components';
import { useAdminDeleteUser } from 'hooks';
import { useState } from 'react';

export function UsersViewPage() {
  const { userProfileId } = useParams();
  const { getProfileImage } = useProfileImage();
  const { organizationSlug, isRootDomain } = useAppContext();
  const { reqAdminDeleteUser, isLoadingAdminDeleteUser } = useAdminDeleteUser({
    getSilently: false,
  });
  const [promptToDeleteUserId, setPromptToDeleteUserId] = useState<
    string | undefined
  >(undefined);

  const navigate: NavigateFunction = useNavigate();
  const { setError, setSuccess } = useSnackbar();

  // User Profiles
  const {
    adminUserProfiles,
    getErrorsAdminUserProfiles,
    getCode,
    isErrorAdminUserProfiles,
  } = useAdminUserProfiles({
    getSilently: true,
    forceRefresh: true,
    getSilentlyUrl: !isRootDomain
      ? `/admin/userProfiles/${userProfileId}`
      : `/superadmin/userProfiles/${userProfileId}`,
    queryParams: {
      ...(organizationSlug && { organizationSlug }),
    },
  });
  const userProfile = adminUserProfiles().find(
    ({ id }) => id === userProfileId,
  );
  const userId = userProfile?.userId;

  const userPicture: string | undefined = userProfile?.auth0Picture;
  const userBlobPicture: string | undefined = userProfile?.picture;

  // Delete User
  const isLoadingDeleteUser = isLoadingAdminDeleteUser();

  async function handleDeleteUser(userId: string | undefined) {
    const getAdminDeleteUserResults = await reqAdminDeleteUser({
      method: 'delete',
      url: isRootDomain
        ? `/superadmin/users/${userId}`
        : `/admin/users/${userId}`,
      params: {
        ...(isRootDomain && {
          organizationSlug: userProfile?.organizationSlug,
        }),
      },
    });

    if (getAdminDeleteUserResults.status !== 'success') {
      console.error({ getAdminDeleteUserResults });
      setError({
        message:
          'There was an issue deleted this users account, please try again',
        title: 'Delete User',
      });
    } else {
      console.error({ getAdminDeleteUserResults });
      setSuccess({
        title: 'Delete User',
        message: 'The users account has been deleted',
      });
      navigate('/users');
    }
  }

  function isDeleteModalOpen() {
    return promptToDeleteUserId !== undefined || isLoadingDeleteUser;
  }

  function onDeleteModalConfirm() {
    if (!promptToDeleteUserId) return;
    handleDeleteUser(userId);
    setPromptToDeleteUserId(undefined);
  }

  const deleteUserMenuItems: DropDownItem[] = [
    {
      onClick: () => setPromptToDeleteUserId(userId),
      label: 'Delete User',
      icon: <DeleteIcon />,
      description:
        'This will delete all profiles and meta data linked to this account',
      key: '1-delete-user',
    },
  ];

  if (isErrorAdminUserProfiles()) {
    return (
      <ErrorView
        title="Could not find user"
        message={getErrorsAdminUserProfiles()
          .flatMap((error) => error.message)
          .join('/n')}
        code={getCode()}
        iconVariant={'error'}
        buttons={[]}
      />
    );
  }
  return (
    <>
      <DeleteUserModal
        isOpen={isDeleteModalOpen()}
        onConfirm={onDeleteModalConfirm}
        onCancel={() => setPromptToDeleteUserId(undefined)}
        buttons={{ loading: isLoadingDeleteUser }}
      />
      <SubPageView
        title="User Profile"
        subHeading={
          userProfile
            ? userProfile.displayName
            : { isLoading: true, text: 'Lorem Ipsum', variant: 'h2' }
        }
        subtitles={
          userProfile
            ? [
                {
                  text: (
                    <Chip
                      label={userProfile.email}
                      color="gray"
                      variant="outlined"
                    />
                  ),
                },
                userProfile?.jobTitle && {
                  text: userProfile?.jobTitle,
                },
                userProfile?.townOrCity && {
                  text: userProfile?.townOrCity,
                },
                userProfile?.country && {
                  text: userProfile?.country,
                },
                {
                  text: `Registered on: ${new Date(
                    userProfile.createdAt,
                  ).toLocaleDateString()}`,
                },
                {
                  text: `Last active: ${new Date(
                    userProfile.updatedAt
                      ? userProfile.updatedAt
                      : userProfile.createdAt,
                  ).toLocaleDateString()}`,
                },
              ]
            : [
                {
                  isLoading: true,
                  text: 'Lorem ipsum dolor',
                  variant: 'body1',
                },
                {
                  isLoading: true,
                  text: 'Lorem ipsum dolor',
                  variant: 'body1',
                },
                {
                  isLoading: true,
                  text: 'Lorem ipsum dolor',
                  variant: 'body1',
                },
              ]
        }
        avatarSrc={getProfileImage({
          profilePicture: userBlobPicture,
          userPicture: userPicture,
        })}
        onBackButtonClick={() => navigate(-1)}
        dropDownButton={{
          label: 'Delete User',
          icon: <PersonOffIcon />,
          dropDownItems: deleteUserMenuItems,
        }}
      >
        {userProfileId && userId ? (
          <ProfileProgramOverviewListContainer profileId={userProfileId} />
        ) : (
          <></>
        )}
      </SubPageView>
    </>
  );
}
