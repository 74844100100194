import {
  GuiderAdminLogo,
  GuiderIcon,
  ButtonStack,
  ButtonStackProps,
  Stack,
  Avatar,
  Text,
} from '@guider-global/ui';
import { Typography as MuiTypography } from '@mui/material';
let { version: REACT_APP_VERSION } = require('../../../package.json');

export type OnboardingViewProps = {
  avatarSrc?: string;
  title: string;
  description?: string;
  buttonStackProps: ButtonStackProps;
};

export function OnboardingView({
  avatarSrc,
  title,
  description,
  buttonStackProps,
}: OnboardingViewProps) {
  return (
    <Stack direction="column" align="center">
      <Stack direction="column" align="center" sx={{ marginBottom: 3 }}>
        {!avatarSrc ? (
          <GuiderIcon size="large" />
        ) : (
          <Avatar
            size={'medium'}
            src={avatarSrc}
            sx={{ objectFit: 'contain !important' }}
          />
        )}
        <GuiderAdminLogo size="medium" />
        <MuiTypography variant="h1">{title}</MuiTypography>
        {description && (
          <MuiTypography variant="body1">{description}</MuiTypography>
        )}
      </Stack>
      <ButtonStack {...buttonStackProps} />
      <footer
        style={{
          position: 'absolute',
          bottom: 5,
          margin: '0 auto',
        }}
      >
        <Text text={`v${REACT_APP_VERSION}`} variant="caption" />
      </footer>
    </Stack>
  );
}
