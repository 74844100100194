import { Router } from '@remix-run/router';
import { AppThemeContainer, AuthThemeContainer } from 'containers';
import { OverMetricsTableModelProvider } from 'context';
import {
  ErrorPage,
  IntegrationsPage,
  LoginPage,
  LogoutPage,
  MongoMetricsPage,
  OrganizationsPage,
  PlaceholderPage,
  UsersViewPage,
} from 'pages';
import { ProgramMetricsPage } from 'pages/ProgramMetricsPage';
import { UsersSearchPage } from 'pages/UsersSearchPage';
import { createBrowserRouter } from 'react-router-dom';
import { DatadogWrapper, ProtectedWrapper, SnackbarWrapper } from 'wrappers';

export const router: Router = createBrowserRouter([
  {
    path: '/',
    errorElement: (
      <AuthThemeContainer>
        <ErrorPage
          title="An unexpected error has occurred"
          iconVariant="error"
          buttons={[
            {
              key: 'continue',
              label: 'Continue',
              variant: 'contained',
              href: '/',
            },
            {
              key: 'request-support',
              label: 'Request Support',
              variant: 'contained',
              onClick: () => window.Intercom('show'),
            },
            {
              key: 'logout',
              label: 'Logout',
              variant: 'text',
              href: '/logout',
            },
          ]}
        />
      </AuthThemeContainer>
    ),
    element: (
      <SnackbarWrapper>
        <DatadogWrapper />
      </SnackbarWrapper>
    ),
    children: [
      /** '/' Route redirect */
      {
        path: '/',
        element: <AuthThemeContainer />,
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
        ],
      },
      /** Authetication routes */
      {
        path: '/login',
        element: <AuthThemeContainer />,
        children: [
          {
            index: true,
            element: <LoginPage />,
          },
        ],
      },
      {
        path: '/logout',
        element: <AuthThemeContainer />,
        children: [
          {
            index: true,
            element: <LogoutPage />,
          },
        ],
      },
      {
        path: '/forgot-password',
        element: <AuthThemeContainer />,
        children: [
          {
            index: true,
            element: <PlaceholderPage title="Forgot password" />,
          },
        ],
      },
      {
        path: '/request-access',
        element: <AuthThemeContainer />,
        children: [
          {
            index: true,
            element: <PlaceholderPage title="Request Access" />,
          },
        ],
      },
      {
        path: '/organizations',
        element: <AuthThemeContainer />,
        children: [
          {
            index: true,
            element: <OrganizationsPage />,
          },
        ],
      },

      {
        path: '/metrics',
        element: <AppThemeContainer />,
        children: [
          {
            index: true,
            element: (
              <OverMetricsTableModelProvider>
                <ProtectedWrapper scope="admin:metrics">
                  <MongoMetricsPage />
                </ProtectedWrapper>
              </OverMetricsTableModelProvider>
            ),
          },
        ],
      },
      {
        path: '/metrics/:programSlug',
        element: (
          <ProtectedWrapper scope="admin:metrics">
            <ProgramMetricsPage />
          </ProtectedWrapper>
        ),
      },
      {
        path: '/programs',
        element: <AppThemeContainer />,
        children: [
          {
            path: '/programs/:programSlug',
            element: (
              <ProtectedWrapper scope="admin:metrics">
                <ProgramMetricsPage />
              </ProtectedWrapper>
            ),
          },
        ],
      },
      // TODO Enable when dashboard is done
      // {
      //   path: '/dashboard',
      //   element: <AppThemeContainer />,
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <ProtectedWrapper scope="admin:dashboard">
      //           <PlaceholderPage title="Dashboard Home" />
      //         </ProtectedWrapper>
      //       ),
      //     },
      //   ],
      // },
      {
        path: '/integrations',
        element: <AppThemeContainer />,
        children: [
          {
            index: true,
            element: (
              <ProtectedWrapper scope="admin:integrations">
                <IntegrationsPage />
              </ProtectedWrapper>
            ),
          },
        ],
      },
      {
        path: '/users',
        element: <AppThemeContainer />,
        children: [
          {
            index: true,
            element: (
              <ProtectedWrapper scope="admin:users">
                <UsersSearchPage />
              </ProtectedWrapper>
            ),
          },
          {
            path: '/users/:userProfileId',
            element: (
              <ProtectedWrapper scope="admin:users">
                <UsersViewPage />
              </ProtectedWrapper>
            ),
          },
        ],
      },
      {
        path: '/program-toolkit',
        element: <AppThemeContainer />,
        children: [
          {
            index: true,
            element: (
              <ProtectedWrapper>
                <PlaceholderPage title="Program Toolkit" />
              </ProtectedWrapper>
            ),
          },
        ],
      },
    ],
  },
  /** Catch all errors */
  {
    path: '/*',
    element: (
      <AuthThemeContainer>
        <ErrorPage
          title={'Page not found'}
          messageOverride="You didn't break the internet, but we can't find what you’re are looking for. "
          buttons={[
            {
              key: 'continue',
              label: 'Continue',
              variant: 'contained',
              href: '/',
            },
          ]}
        />
      </AuthThemeContainer>
    ),
  },
]);
