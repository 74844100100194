import { getSubDomain } from '@guider-global/front-end-utils';
import { AppContext, IAppContext } from 'context';
import { ReactNode, useMemo, useState } from 'react';

export interface AppWrapperProps {
  children: ReactNode;
}

export function AppWrapper({ children }: AppWrapperProps) {
  const subdomain = getSubDomain();

  const [organizationSlug, setOrganizationSlug] = useState<string | undefined>(
    subdomain === 'guider' ? undefined : subdomain,
  );

  const handleOrganization = (organizationSlug: string) => {
    if (organizationSlug === 'guider') {
      setOrganizationSlug(undefined);
    } else {
      setOrganizationSlug(organizationSlug);
    }
  };
  const appContext: IAppContext = useMemo(
    () => ({
      organizationSlug,
      isRootDomain: subdomain === 'guider',
      setOrganizationSlug: handleOrganization,
    }),
    [organizationSlug, subdomain],
  );

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
}
